import React, { useEffect, useRef, useState } from "react";

import { Link, graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Button from "../components/Button/Button";
import * as styles from "./index.module.scss";

import gsap from "gsap";

import { theme } from "../../tailwind.config";
import ProfilfelderOverview from "../components/ProfilfelderOverview";
import MeldungPreview from "../components/MeldungPreview";

//Background Image: https://youtu.be/QCs7vQiMsYA
import { StaticImage } from "gatsby-plugin-image";

//Animate text
const textIntro = (elem) => {
  gsap.from(elem.children, {
    yPercent: 20,
    opacity: 0,
    stagger: 0.2,
    duration: 2,
    ease: "inOut",
    delay: 1,
  });
};

function IndexPage({ data }) {
  const { colors } = theme.extend;
  let intro = useRef(null);

  useEffect(() => {
    textIntro(intro);
  }, []);

  const [frameColor, toggleFrameColor] = useState(colors.primary);

  return (
    <Layout>
      <SEO
        keywords={[
          `Bayreuth`,
          `Institut`,
          `Management`,
          `Ethik`,
          `i-em`,
          `iem`,
          `Unternehmensethik`,
          `sustainabilitytransformationmanagement`,
          `sustainabilitytransformationmanager`,
          `twintransformer`,
          `Brink`,
          `CDR`,
          `Digital Responsibility`,

        ]}
        title="i-em"
      />
      <div className="bg-light">
        <section id="hero" style={{ zIndex: "0" }}>  



         <div
            className="border-8 border-solid transition-all duration-500 ease-in-out position: relative"
            style={{ borderWidth: "2em", borderColor: frameColor }}
          >

            <StaticImage 
                alt="Backgroudnimage"
                style={{position: "absolute"}}
                src= "https://res.cloudinary.com/i-em/image/upload/v1647940941/Website/i-em_Backgroundimage_tdneou.png" 
                className={styles.backgroundImage}
                />                 


            <div className="pt-48 pb-10 container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">

              <div className="flex flex-col w-full md:w-4/6 justify-center items-start text-center md:text-left position: relative">
                <h1
                  ref={(el) => (intro = el)}
                  className={`${styles.heroHeader}  my-5 text-3xl md:text-5xl font-header leading-snug tracking-wide`}
                >
                  <span className={`${styles.underline} inline-block`}>
                    Unser Ziel?
                  </span>
                  <br />
                  <span className={`${styles.underline} `}>
                    Eine nachhaltige Gesellschaft.
                  </span>
                </h1>

                <p className="leading-normal font-light text-xl mb-4 color-black">
                Die Arbeit am InnovationLab (iLab) Ethik und Management verbinden wir daher mit der Verpflichtung, an der Lösung grundlegender und akuter gesellschaftlicher Probleme mitzuwirken. Wir glauben, dass die Zwillingstransformation aus Digitalisierung und Nachhaltigkeit nur auf der Grundlage von Werten erfolgen kann. Daran arbeiten wir in sechs Profilfeldern - in Forschung, Lehre und Beratung. Das Studienprogramm Philosophy & Economics ist unser Rückgrat. 
                </p>
              </div>

              <div className="w-full md:w-3/5 py-6 text-center"></div>
            </div>
          </div>
        </section>
        <section id="saeulen" className="md:mb-10 mb-6">
          <div className="container px-3">
            <div className="grid md:grid-cols-3 grid-cols-1 sm:gap-8 md:gap-4 xl:gap-8">
              <div
                onMouseEnter={() => toggleFrameColor(colors.primary)}
                className={`${styles.card} md:-my-8 mt-2 px-5 py-6 pb-10 bg-primary text-white relative`}
              >
                <h2 className="text-2xl mb-3 font-bold">Forschung.</h2>
                <p className="font-light text-xl leading-normal">
                  Monografien, Sammelbände, Artikel und mehr.
                </p>
                <Button
                  className="absolute bottom-0 mb-4"
                  shadow
                  to="/forschung"
                >
                  weiter
                </Button>
              </div>
              <div
                onMouseEnter={() => toggleFrameColor(colors.secondary)}
                className={`${styles.card} md:-my-8 mt-2 px-5 py-6 pb-10 bg-secondary text-white relative`}
              >
                <h2 className="text-2xl mb-3 font-bold">Lehre.</h2>
                <p className="font-light text-xl">
                  Lehrveranstaltungen, Qualifikationsarbeiten und Praktika.
                </p>
                <Button className="absolute bottom-0 mb-4" to="/lehre">
                  weiter
                </Button>
              </div>
              <div
                onMouseEnter={() => toggleFrameColor(colors.tertiary)}
                className={`${styles.card} md:-my-8 mt-2 px-5 py-6 pb-10 bg-tertiary text-white relative`}
              >
                <h2 className="text-2xl mb-3 font-bold">Transfer.</h2>
                <p className="font-light text-xl">
                  Beratung, Kooperationen, Weiterbildungen und mehr.
                </p>
                <Button className="absolute bottom-0 mb-4" to="/transfer">
                  weiter
                </Button>
              </div>
            </div>
          </div>
        </section>

        <ProfilfelderOverview />

        <section id="team">
          <div className="container mx-auto py-10">
            <div className="grid md:grid-cols-2 md:gap-6 xl:gap-12">
              <div>
                <h2 className="font-header font-light text-4xl">
                  Das sind wir.
                </h2>
                <p className="font-light text-lg leading-loose">
                  Die Arbeit am InnovationLab Ethik und Management
                  verbinden wir mit dem Anspruch, an der Lösung grundlegender
                  und akuter gesellschaftlicher Probleme mitzuwirken. Unser
                  Ansatz basiert auf Interdisziplinarität und Praxisnähe an der
                  Schnittstelle von Philosophie und Ökonomie.
                </p>
                <Button to="/ueber" className="font-xl mt-4">
                  mehr
                </Button>
                <Button to="/kontakt" className="font-xl mt-4">
                  Kontakt
                </Button>
                <Button to="https://www.linkedin.com/company/i-em/" className="font-xl mt-4">
                  LinkedIn
                </Button>
              </div>
              <Link to="/ueber#team" isAnchor>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-6 mt-6">
                  {data.allStrapiPeople.edges.map(({ node }) => {
                    if (node.Profilbild) {
                      return (
                        <div
                          key={node.Nachname}
                          className="rounded-full relative"
                        >
                          <Img
                            style={{
                              width: 150,
                              height: 150,
                              display: "block",
                            }}
                            className="rounded-full shadow-lg mx-auto"
                            fixed={
                              node.Profilbild.localFile.childImageSharp.fixed
                            }
                          />
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </Link>
            </div>
          </div>
        </section>

        <section id="meldungen">
          <div className="container my-auto py-10 grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-8">
            <div className="">
              <h2 className="font-header font-light text-4xl">Aktuelles</h2>
              <p className="font-light text-lg leading-loose">
                Aktuelle Meldungen rund um das iLab.
              </p>
              <Button to="/aktuelles" className="font-xl mt-4">
                mehr
              </Button>
              <Button to="/stellenangebote" className="font-xl mt-4">
                Offene Stellenangebote
              </Button>
              <Button to="/call-for-papers" className="font-xl mt-4">
                Call for Papers
              </Button>
            </div>
            {data.allWpPost.edges.map(({ node }) => (
              <MeldungPreview node={node} />
            ))}
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  query {
    allStrapiPeople(filter: { Status: { Team: { eq: true } } }) {
      edges {
        node {
          Vorname
          Nachname
          Status {
            Autor
            Netzwerk
            Team
          }
          Profilbild {
            localFile {
              childImageSharp {
                fixed(width: 200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    allWpPost(
      filter: { status: { eq: "publish" } }
      sort: { fields: date, order: DESC }
      limit: 8
    ) {
      edges {
        node {
          title
          slug
          excerpt
          date(formatString: "DD.MM.YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    allStrapiAktivitaet {
      nodes {
        Aktivitaetslink
        Content
        Kurzbeschreibung
        Slug
        Titel
        kompetenzfelds {
          Titel
        }
        aktivitatskategories {
          Slug
          Titel
        }
        saules {
          Slug
        }
      }
    }
  }
`;
